export const useWindowEvents = () => {

    const callbacks = {};
    const debouncedCallbacks = {};


    const onWindowEvent = (eventName, callback) => {
        if (typeof callbacks[eventName] === "undefined") callbacks[eventName] = [];
        callbacks[eventName].push(callback);
    }

    const onDebouncedWindowEvent = (eventName, callback) => {
        if (typeof debouncedCallbacks[eventName] === "undefined") debouncedCallbacks[eventName] = [];
        debouncedCallbacks[eventName].push(callback);
    }

    onMounted(() => {
        Object.keys(debouncedCallbacks).forEach(eventName => {
            window.addEventListener(eventName, debounce((event) => {
              debouncedCallbacks[eventName].forEach(callback => callback(event));
            }, 250))
        });

        Object.keys(callbacks).forEach(eventName => {
            window.addEventListener(eventName, (event) => {
                callbacks[eventName].forEach(callback => callback(event));
            })
        })
    });

    onUnmounted(() => {
        Object.keys(debouncedCallbacks).forEach(eventName => {
            window.removeEventListener(eventName, debounce((event) => {
                callbacks[eventName].forEach(callback => callback(event));
            }, 250))
        });

        Object.keys(callbacks).forEach(eventName => {
            window.removeEventListener(eventName, (event) => {
                callbacks[eventName].forEach(callback => callback(event));
            })
        })
    });

    return {
        onWindowEvent,
        onDebouncedWindowEvent
    }
}
